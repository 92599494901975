<template>
    <div class="panel-pricing-wrapper">
        <a @click.prevent="openModal" href="#" class="btn btn-primary btn-block w-100">Start Conversation</a>

        <!-- Modal -->
        <vue-final-modal v-model="showModal">
            <div class="modal-dialog" role="document" v-if="panelConfiguration">
                <div class="modal-content">
                    <div class="modal-header border-bottom-0">
                        <h5 class="modal-title">
                            Start Conversation With {{ panelConfiguration.display_name }}
                        </h5>
                        <button type="button" class="btn-close" aria-label="Close" @click="showModal = false"></button>
                    </div>
                    <div class="modal-body" v-if="localContext" style="text-align: left;">

                        <!-- Select speciality -->
                        <div v-if="stage == 0">

                            <p>
                                What does your enquiry relate to?
                            </p>

                            <div class="speciality">
                                <a v-for="(speciality, i) in localContext.specialities" :key="i"
                                    @click.prevent="selectSpeciality(speciality)" href="#"
                                    class="btn btn-outline-primary me-2 mb-1">
                                    {{ speciality.name }}
                                </a>
                            </div>

                        </div>
                        <!-- / Select speciality -->

                        <!-- Has selected speciality -->
                        <div v-if="stage == 1" class="recommend-form">
                            <!-- Speciality -->
                            <div class="mb-3">
                                <label class="form-label" for="speciality">Speciality:</label> {{ form.speciality.name }}
                            </div>
                            <!-- / Speciality -->


                            <!-- Job Description -->
                            <div class="mb-4">
                                <label class="form-label" for="jobDescription">What would you like to discuss?</label>
                                <textarea :disabled="isSaving"
                                    placeholder="Just a bit of detail to help your lawyers with the context, including whether you prefer fixed or variable fees"
                                    v-model="form.description" rows="4" class="form-control" id="jobDescription" />
                            </div>
                            <!-- / Job Description -->


                        </div>
                        <!-- / Has selected speciality -->

                        <!-- Setup account -->
                        <div v-if="stage == 2">

                            <div class="row">
                                <div class="col-md-6">
                                    <div class="mb-4">
                                        <label class="form-label" for="name">Your Name <span
                                                class="text-danger">*</span></label>
                                        <input v-model="createAccountForm.name" class="form-control form-control-lg"
                                            type="text" id="name" :disabled="isSaving" placeholder="Enter your name"
                                            required>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="mb-4">
                                        <label class="form-label" for="email">Your Email <span
                                                class="text-danger">*</span></label>
                                        <input v-model="createAccountForm.email" class="form-control form-control-lg"
                                            type="text" id="email" :disabled="isSaving" placeholder="Enter your email"
                                            required>
                                    </div>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-md-6">
                                    <div class="mb-4">
                                        <label class="form-label" for="phone">Phone Number<span
                                                class="text-danger">*</span></label>
                                        <input v-model="createAccountForm.phone" class="form-control form-control-lg"
                                            type="tel" id="phone" :disabled="isSaving"
                                            placeholder="Enter your mobile number" required>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-4">
                                        <label class="form-label" for="password">Password <span
                                                class="text-danger">*</span></label>
                                        <input v-model="createAccountForm.password" class="form-control form-control-lg"
                                            type="password" id="password" :disabled="isSaving"
                                            placeholder="Enter a password" required>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="mb-4">
                                        <label class="form-label" for="companyName">Company Name (If Applicable)</label>
                                        <input v-model="createAccountForm.company_name" class="form-control form-control-lg"
                                            type="text" id="companyName" :disabled="isSaving"
                                            placeholder="Enter your company name">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- / Setup account -->

                    </div>

                    <!-- Go to speciality stage -->
                    <div class="modal-footer" v-if="localContext && form.speciality && stage == 1">
                        <button type="button" class="btn btn-secondary btn-sm" @click.prevent="previousStage">Back</button>
                        <button :disabled="!canSave || isSaving" @click.prevent="nextStage" type="button"
                            class="btn btn-primary btn-sm">
                            <spinner v-if="isSaving" class="spinner-border-sm me-2"></spinner>
                            Next
                        </button>
                    </div>
                    <!-- / Go to speciality stage -->

                    <!-- Setup account stage -->
                    <div class="modal-footer" v-if="localContext && form.speciality && stage == 2">
                        <button type="button" class="btn btn-secondary btn-sm" @click.prevent="previousStage">Back</button>
                        <button :disabled="!canSave || isSaving" @click.prevent="attemptCreateAccountAndStartConversation"
                            type="button" class="btn btn-primary btn-sm">
                            <spinner v-if="isSaving" class="spinner-border-sm me-2"></spinner>

                            {{ isSaving ? 'Creating Account...' : 'Create Account & Start Conversation' }}
                        </button>
                    </div>
                    <!-- / Setup account stage -->

                </div>
            </div>
        </vue-final-modal>
        <!-- / Modal -->
    </div>
</template>

<script>
import _ from 'lodash';
import Display from '../../mixins/Display';
import RequiresContext from '../../mixins/RequiresContext';
import Spinner from '../Spinner.vue';
import { useToast } from 'vue-toastification';
const toast = useToast();
import axios from 'axios';
import { mapGetters, mapActions } from 'vuex';
import Forms from '../../mixins/Forms';

export default {
    props: ['panelConfiguration'],
    components: { Spinner },
    mixins: [Display, RequiresContext, Forms],
    data() {
        return {
            showModal: false,

            stage: 0,

            form: {
                speciality: null,
                description: '',
            },

            createAccountForm: {
                name: '',
                email: '',
                phone: '',
                password: '',
                password_confirmation: '',
                company_name: ''
            },

            isSaving: false,
            serverErrors: []
        }
    },
    computed: {
        ...mapGetters('whitelabelConfiguration', {
            whitelabelConfigurationId: 'whitelabelConfigurationId'
        }),

        canSave() {
            return this.form.speciality && this.form.description;
        }
    },
    methods: {
        ...mapActions('auth', {
            handleUserRegistered: 'handleUserRegistered'
        }),

        reset() {
            this.form = {
                speciality: null,
                description: '',
            };
        },

        clearSpeciality() {
            this.form.speciality = null;
            this.stage = 0;
        },

        selectSpeciality(speciality) {
            this.form.speciality = speciality;
            this.stage = 1;
        },

        determineSearchParams() {
            return {
                speciality_id: this.form.speciality ? this.form.speciality.id : null,
                description: this.form.description,
                company_id: this.panelConfiguration.company_id
            };
        },

        openModal() {
            this.showModal = true;
        },

        nextStage() {
            if (this.stage == 0) {
                this.stage++;
            }
            if (this.stage == 1) {
                if (this.canSave) {
                    this.stage++;
                }
            }
            if (this.stage > 1) {
                return false;
            }
        },

        previousStage() {
            if (this.stage > 0) {
                this.stage--;
            }
        },

        attemptCreateAccountAndStartConversation() {
            let vm = this;
            vm.isSaving = true;
            axios.post(window.API_BASE + '/client-register', {
                name: vm.createAccountForm.name,
                email: vm.createAccountForm.email,
                password: vm.createAccountForm.password,
                // password_confirmation: vm.form.password_confirmation,
                password_confirmation: vm.createAccountForm.password,
                phone: vm.createAccountForm.phone,
                company_name: vm.createAccountForm.company_name,
                wcid: vm.whitelabelConfigurationId
            }).then(r => {
                vm.handleUserRegistered(r.data).then(r => {
                    vm.isSaving = false;
                    vm.startConversation();
                    // vm.$emit('registered');
                }).catch(e => {
                    console.log(e);
                    vm.isSaving = false;
                    vm.setAndNotifyErrors(e);
                });
            }).catch(e => {
                console.log(e);
                vm.isSaving = false;
                vm.setAndNotifyErrors(e);
            });
        },

        startConversation() {
            this.isSaving = true;
            this.serverErrors = [];

            axios.post(window.API_BASE + '/jobs/start-conversation-with-panel-provider', this.determineSearchParams())
                .then(r => {
                    this.isSaving = false;
                    this.showModal = false;
                    this.reset();
                    this.$router.push('/jobs/' + r.data.id);
                })
                .catch(error => {
                    this.isSaving = false;
                    let errors;
                    if (typeof e.response.data === 'object') {
                        errors = _.flatten(_.toArray(e.response.data.errors));
                    } else {
                        errors = ['Something went wrong. Please try again.'];
                    }
                    this.serverErrors = errors;
                    toast.error('There was an error starting your conversation. Please try again.');
                });
        }
    }
}
</script>

<style scoped lang="scss">
.panel-pricing-wrapper {
    width: 100%;
}

.btn-outline-primary {
    color: rgb(var(--primary-colour)) !important;
    border-color: rgb(var(--primary-colour)) !important;
}

.btn-outline-primary:hover {
    background-color: rgb(var(--primary-colour)) !important;
    color: white !important;
}
</style>